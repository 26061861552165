<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>咨询建议</span>
                <a-radio-group
                v-model="form.type"
                button-style="solid"
                @change="typeChange"
                >
                <a-radio-button :value="undefined"> 全部 </a-radio-button>
                <a-radio-button :value="1"> 咨询 </a-radio-button>
                <a-radio-button :value="2"> 建议 </a-radio-button>
                <a-radio-button :value="3"> 投诉 </a-radio-button>
                <a-radio-button :value="4"> 表扬 </a-radio-button>
                </a-radio-group>
            </a-space>
        </div>
        <div class="search-box">
            <a-alert v-if="form.type==1" :message="'温馨提示：今日新增 '+todayMessage+' 条咨询'" type="warning" show-icon />
            <a-alert v-else-if="form.type==2" :message="'温馨提示：今日新增 '+todayMessage+' 条建议'" type="warning" show-icon />
            <a-alert v-else-if="form.type==3" :message="'温馨提示：今日新增 '+todayMessage+' 条投诉'" type="warning" show-icon />
            <a-alert v-else-if="form.type==4" :message="'温馨提示：今日新增 '+todayMessage+' 条表扬'" type="warning" show-icon />
            <a-alert v-else :message="'温馨提示：今日共新增 '+todayMessage+' 条'" type="warning" show-icon />
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-range-picker @change="handlerTime" v-model="selTime" style="width: 240px" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间','结束时间']"></a-range-picker>
                    <a-input v-model="form.releaseName" style="width: 200px" placeholder="发布人"></a-input>
                    <a-select v-model="form.status" style="width: 200px" placeholder="请选择状态">
                        <a-select-option :value="0">未反馈</a-select-option>
                        <a-select-option :value="1">反馈中</a-select-option>
                        <a-select-option :value="2">已反馈</a-select-option>
                    </a-select>
                    <a-select v-model="form.score" style="width: 200px" placeholder="请选择评分">
                        <a-select-option :value="0">差评</a-select-option>
                        <a-select-option :value="1">中评</a-select-option>
                        <a-select-option :value="2">好评</a-select-option>
                    </a-select>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange" :scroll="{ x: 1800 }"
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="rate" slot-scope="text,record">
                    <a-rate :default-value="(record.score/2.0)" disabled allow-half></a-rate>
                </template>
                <template slot="action" slot-scope="text,record">
                    <span><a @click=detail(record)>详情</a></span>
                    <span><a style="margin-left: 8px" @click=reply(record)>回复</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
        <a-drawer :title="activeTitle" :width="720" :visible="detailShow" :body-style="{ paddingBottom: '60px' }" @close="detailClose">
            <div class="drawer-content">
                <span>基本信息</span>
                <a-divider></a-divider>
                <a-descriptions style="padding: 16px" :column="1">
                    <a-descriptions-item label="类型">
                        {{adviceInfo.type==1?'咨询':2?'建议':3?'投诉':'表扬'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="照片">
                        <img v-for="(item,index) in adviceInfo.imgUrl" 
							:key="index" :src="$ImgUrl(item.url)" style="height: 90px;width: 160px" />
                    </a-descriptions-item>
                    <a-descriptions-item label="内容">
                        {{adviceInfo.content}}
                    </a-descriptions-item>
                    <a-descriptions-item label="反馈内容">
                        <a-table :columns="replyColumns" :data-source="adviceInfo.voUserAdviceDetailList" :pagination="false">
                            <template slot="create" slot-scope="text,row">
                                {{row.createName}}({{row.createUserType==1?'住户':'物业'}}) {{row.createDate}}
                            </template>
                            <template v-if="activeTitle=='回复'" slot="action" slot-scope="text,row">
                                <a @click="singelReply(row)">回复</a>
                            </template>
                        </a-table>
                    </a-descriptions-item>
                    <a-descriptions-item label="评分星级" v-if="activeTitle=='咨询详情'">
                        <a-rate :value="(adviceInfo.score*1)/2.0" disabled allow-half></a-rate>
                    </a-descriptions-item>
                </a-descriptions>
                <div v-if="activeTitle=='回复'">
                    <span>回复反馈</span>
                    <a-divider></a-divider>
                    <a-form-model :model="repForm" :rules="rules" style="padding: 16px" layout="inline">
                        <a-form-model-item label="反馈" prop="content">
                            <a-textarea v-model="repForm.content" :autoSize="{ minRows: 8}" placeholder="请输入回复反馈" style="width: 500px">
                            </a-textarea>
                        </a-form-model-item>
                    </a-form-model>
                </div>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="replyConfirm" type="primary" :style="{ marginRight: '8px' }"> 确认 </a-button>
                <a-button @click="detailClose"> 关闭 </a-button>
            </div>
            <a-modal :visible="singelReplyVisible" title="回复" @ok="singleConfirm" @cancel="singelReplyVisible=false">
                <a-form-model-item label="回复">
                    <a-textarea v-model="singleForm.content" :autoSize="{ minRows: 4}" placeholder="请输入回复内容" style="width: 500px">
                    </a-textarea>
                </a-form-model-item>
            </a-modal>
        </a-drawer>
    </div>
</template>

<script>
import { getAdviceList,delAdvice,adviceDetail,replyDetail,getTodayAdvice } from "@/api/operation/recommand"
import { columns,replyColumns } from "./depend/config";
export default {
    data() {
        return {
            form: {
                type: undefined,
                releaseName: '',
                releaseDateStart: '',
                releaseDateEnd: '',
                status: undefined,
                score: undefined,
            },
            repForm: {
                appAdviceId: undefined,
                content: '',
                parentId: 0
            },
            singleForm: {
                appAdviceId: undefined,
                content: '',
                parentId: 0
            },
            selTime: [],
            rules: {
                content: [{ required: true, message: "请输入回复", trigger: "blur" }],
            },
            activeName: 0,
            columns: columns,
            replyColumns: replyColumns,
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            selectedRowKeys: [],
            //详情
            activeTitle: '',
            detailShow: false,
            adviceInfo: {voUserAdviceDetailList: []},
            //回复窗口
            singelReplyVisible: false,
            //今日信息
            todayMessage: undefined,
        }
    },
    created() {
        this.getApi()
        this.getData()
    },
    methods: {
        getApi() {
            getTodayAdvice({type: this.form.type}).then(res => {
                let data = res.data;
                this.todayMessage = data
            })
        },
        getData() {
            let obj = Object.assign( this.form, {pageNum: this.pagination.current,size: this.pagination.pageSize})
            getAdviceList(obj).then(res => {
                this.pagination.total = res.data.total;
                this.tableData = res.data.rows
            })
        },
        reset() {
            this.form = {
                type: undefined,
                releaseName: '',
                releaseDateStart: undefined,
                releaseDateEnd: undefined,
                status: undefined,
                score: undefined,
            },
            this.selTime = [];
            this.getData();
        },
        handlerChange(val) {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        detailClose() {
            this.adviceInfo = {};
            this.repForm = {
                appAdviceId: undefined,
                content: '',
                parentId: 0
            },
            this.detailShow = false
        },
        //切换
        typeChange(val) {
            this.getData();
            this.getApi()
        },
        //改变时间
        handlerTime(val) {
            this.form.releaseDateStart = val[0];
            this.form.releaseDateEnd = val[1];
        },
        //回复和删除
        detail(val) {
            this.activeTitle = '咨询详情'
            adviceDetail({adviceId: val.id}).then(res => {
                let data = res.data;
                this.repForm.appAdviceId = data.id
                this.adviceInfo = data
            })
            this.detailShow = true;
        },
        reply(val) {
            this.activeTitle = '回复'
            adviceDetail({adviceId: val.id}).then(res => {
                let data = res.data;
                this.repForm.appAdviceId = data.id
                this.adviceInfo = data
            })
            this.detailShow = true;
        },
        replyConfirm() {
            replyDetail(this.repForm).then(res => {
                if (res.code === 200) {
					this.$message.success(res.msg);
                    this.detailClose();
					this.getData();
				} else {
					this.$message.error(res.msg);
				}
            })
        },
        singleConfirm() {
            replyDetail(this.singleForm).then(res => {
                if (res.code === 200) {
					this.$message.success(res.msg);
                    this.singelReplyVisible = false;
                    this.singleForm = {
                        appAdviceId: undefined,
                        content: '',
                        parentId: 0
                    },
                    this.detailClose();
					this.getData();
				} else {
					this.$message.error(res.msg);
				}
            })
        },
        singelReply(val) {
            this.singleForm.appAdviceId = this.adviceInfo.id
            this.singleForm.parentId = val.id
            this.singelReplyVisible = true
        },
        del(val) {
            if(val.status == 1 || val.status == 2) {
                this.$confirm({
				title: "该条信息您还未回复，是否删除？",
				icon:'delete',
				onOk:async()=>{
					let res = await delAdvice({adviceIds: [val.id]})
					if(res.code === 200){
						this.$message.success(res.msg);
						this.getData()
					} else {
						this.$message.error(res.msg);
					}
				},
			})
            } else {
                this.$confirm({
                    title: "是否确认删除该信息？",
                    icon:'delete',
                    onOk:async()=>{
                        let res = await delAdvice({adviceIds: [val.id]})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
            }
        },
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        //批量删除
        handleMenuClick(data) {
			if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的信息？",
				icon:'delete',
				onOk:async()=>{
                        let res = await delAdvice({adviceIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
		},
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">
.drawer-content {
    padding: 16px;
}
</style>