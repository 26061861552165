import httpService from "@/request"

// 查询所有的咨询建议/投诉表扬
export function getAdviceList(params) {
	return httpService({
		url: `/user/advice/list`,
		method: 'get',
		params: params,
	})
}

// 查询今日咨询建议/投诉表扬数量
export function getTodayAdvice(params) {
	return httpService({
		url: `/user/advice/findTodayAdviceNum`,
		method: 'get',
		params: params,
	})
}

// 批量删除咨询建议/投诉表扬
export function delAdvice(params) {
	return httpService({
		url: `/user/advice/delete`,
		method: 'post',
		data: params,
	})
}

// 根据id查询咨询建议/投诉表扬
export function adviceDetail(params) {
	return httpService({
		url: `/user/advice/findById`,
		method: 'get',
		params: params,
	})
}

// 回复咨询建议/投诉表扬
export function replyDetail(params) {
	return httpService({
		url: `/user/advice/reply`,
		method: 'post',
		data: params,
	})
}