export const columns = [
    {
		title: "序号",
		width: "6%",
		dataIndex: "id",
	},
	{
		title: "内容",
		width: "12%",
		dataIndex: "content",
	},
	{
		title: "类型",
		width: "8%",
		dataIndex: "type",
        customRender: function (type) {
            switch (type) { 
                case 1: return '咨询'
                case 2: return '建议'
                case 3: return '投诉'
                case 4: return '表扬'
            }
        }
	},
	{
		title: "发布人",
		width: "9%",
		dataIndex: "releaseName",
	},
    {
		title: "发布时间",
		width: "12%",
		dataIndex: "releaseDate",
	},
    {
		title: "状态",
		width: "8%",
		dataIndex: "status",
        customRender: function (status) {
            switch (status) { 
                case 3: return '已反馈'
                case 2: return '反馈中'
                case 1: return '未反馈'
            }
        }
	},
    {
		title: "评分数",
		width: "10%",
		dataIndex: "rate",
        scopedSlots: { customRender: "rate" },
	},
    {
		title: "最后一次回复/提问时间",
		width: "14%",
		dataIndex: "lastFeedBackDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const replyColumns = [
    {
		title: "创建人",
		width: "43%",
		dataIndex: "createUserType",
        scopedSlots: { customRender: "create" },
	},
    {
		title: "反馈内容",
		width: "43%",
		dataIndex: "content",
	},
    {
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "13%",
		scopedSlots: { customRender: "action" },
	},
]